/* eslint-disable import/prefer-default-export, no-shadow */

enum typeOfPayments {
  credit = 'CARTÃO DE CRÉDITO',
  accountDebit = 'DÉBITO EM CONTA',
  discountOnSheet = 'DESCONTO EM FOLHA',
}

enum ProductGroupingStatuses {
  active = 'ATIVO',
  inactive = 'INATIVO',
  disabled = 'DESABILITADO',
}

enum discountOnSheetTypes {
  enrollment = 'MATRÍCULA',
  benefit = 'BENEFÍCIO',
}

enum proposalStatus {
  pendingSignature = 'PENDENTE ASSINATURA',
  signed = 'ASSINADO',
  canceled = 'CANCELADA',
  contractCanceledDefinitive = 'CONTRATO CANCELADO-DEFINITIVO',
}

enum proposalElectronicSignature {
  confia = 'CONFIA',
  clickSign = 'CLICKSIGN',
  dataPrev = 'DATAPREV',
  sabemi = 'SABEMI',
}
enum AnnouncementTypes {
  general = 'GERAL',
  news = 'NOVIDADES',
  message = 'MENSAGEM',
}
enum RelationshipAnnouncementStatuses {
  sent = 'ENVIADO',
  received = 'RECEBIDO',
  read = 'LIDO',
}

export {
  AnnouncementTypes, discountOnSheetTypes, ProductGroupingStatuses, proposalElectronicSignature,
  proposalStatus, RelationshipAnnouncementStatuses, typeOfPayments,
};
