/* eslint-disable camelcase */
import { MenuItem, Typography, Button } from '@mui/material';
import { grey, red } from '@mui/material/colors';
import { Box } from '@mui/system';
import Label from 'components/Label';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { announcementSocketNotification } from 'interfaces/Socket';
import { AnnouncementTypes, RelationshipAnnouncementStatuses } from 'enums';
import { format } from 'date-fns';
import { ListChildComponentProps } from 'react-window';
import { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

type ItemData = {
   notifications: announcementSocketNotification[];
   height: number;
   isModalOpen: boolean;
   setRead: (id: number[]) => void;
}

const NotificationItem = memo((
  {
    data: { notifications, height, setRead, isModalOpen }, index, style,
  }: ListChildComponentProps<ItemData>,
) => {
  const navigate = useNavigate();
  const handleClickDestinyURL = (url?: string | null) => {
    if (!url) { return; }
    const result = /^https?:\/\/(.+?)(\/.*)?$/.exec(url ?? '');
    if (!result || !result.length) {
      return;
    }
    const host = result.at(1);
    const path = result.at(2);
    if (host === window.location.host) {
      navigate(path ?? '/');
    } else {
      window.open(url, '_blank');
    }
  };
  const notification = notifications[index];
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const cachedNotificationStatus = useMemo(() => { return notification.status; }, [isModalOpen]);
  useEffect(() => {
    if (notification.status === RelationshipAnnouncementStatuses.read) {
      return;
    }
    setRead([notification.announcementId]);
  }, [setRead, notification]);
  return (
    <MenuItem
      key={notification.announcementId}
      style={style}
      sx={{
        borderBottomStyle: 'solid',
        borderWidth: 1,
        borderColor: grey[300],
        borderRadius: 0,
        maxWidth: 400,
        height,
        cursor: 'unset',
        pt: 1,
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        gap={1}
        sx={{ width: '100%', height: '100%', justifyContent: 'space-around' }}
      >

        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            gap={1}
          >
            <Label color={
              // eslint-disable-next-line no-nested-ternary
              notification.type === AnnouncementTypes.news ? 'approved'
                : notification.type === AnnouncementTypes.message ? 'warning'
                  : 'info'
            }
            >
              {notification.type}
            </Label>
            {cachedNotificationStatus !== RelationshipAnnouncementStatuses.read && (
              <Box
                sx={{
                  backgroundColor: red.A700,
                  height: '10px',
                  width: '10px',
                  borderRadius: '100%',
                }}
              />
            )}
          </Box>
          <Typography
            fontSize="0.8rem"
          >
            {format(new Date(notification.createdAt.replace(/Z$/i, '')), 'dd/MM/yyyy HH:mm:ss')}
          </Typography>
        </Box>

        <Typography
          whiteSpace="break-spaces"
          fontSize="0.9rem"
        >
          {notification.message}
        </Typography>

        <Box>
          <Button
            startIcon={<OpenInNewIcon />}
            onClick={() => { handleClickDestinyURL(notification.destinyUrl); }}
            disabled={!notification.destinyUrl}
          >
            Saiba mais
          </Button>
        </Box>

      </Box>
    </MenuItem>
  );
});

export default NotificationItem;
